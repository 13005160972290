var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.bangla),expression:"!bangla"}],staticClass:"\n        font-heading\n        text-UCPurple\n        font-bold\n        text-2xl\n        2xl:text-3xl\n        text-left\n      "},[_vm._v(" Review your answers ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bangla),expression:"bangla"}],staticClass:"font-heading font-bold text-2xl 2xl:text-3xl text-left"},[_vm._v(" আপনার উত্তর রিভিউ করুন ")]),_c('br'),_c('div',_vm._l((_vm.questions),function(q,idx){return _c('div',{key:idx},[_c('div',{staticClass:"text-xl 2xl:text-2xl font-bold text-left mx-auto mt-4"},[_vm._v(" "+_vm._s(idx + 1)+"."+_vm._s(q.question_description)+" ")]),_c('br'),(q.question_type == 'MCQ')?_c('div',{staticClass:"flex flex-col justify-items-start"},_vm._l((q.options),function(option,index){return _c('div',{key:index,staticClass:"\n              flex\n              justify-between\n              items-center\n              bg-gray-200\n              mt-1\n              text-fontsm text-left\n              p-4\n              w-full\n              options\n              rounded\n              tracking-tighter\n            ",class:{
              'wrong': option == _vm.userAns[idx] && _vm.userAns[idx] != _vm.actualAns[idx],
              'right':
                (option == _vm.userAns[idx] && _vm.userAns[idx] == _vm.actualAns[idx]) ||
                option == _vm.actualAns[idx],
            }},[_vm._v(" "+_vm._s(option)+" "),(
                (option == _vm.userAns[idx] && _vm.userAns[idx] == _vm.actualAns[idx]) ||
                option == _vm.actualAns[idx])?_c('i',{staticClass:"fas fa-check-circle tick"}):_vm._e(),(option == _vm.userAns[idx] && _vm.userAns[idx] != _vm.actualAns[idx])?_c('i',{staticClass:"fas fa-times-circle cross"}):_vm._e()])}),0):(q.question_type == 'image')?_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-3 gap-2"},_vm._l((q.Other.images),function(option,index){return _c('div',{key:index},[_c('img',{staticClass:"rounded-lg img-size",class:{
                'wrong-img':
                  _vm.userAns[idx] == index && _vm.userAns[idx] != _vm.actualAns[idx],
                'correct-img':
                  (_vm.userAns[idx] == index && _vm.userAns[idx] == _vm.actualAns[idx]) ||
                  index == _vm.actualAns[idx],
              },attrs:{"src":option.imgURL,"alt":"answer option"}})])}),0):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }