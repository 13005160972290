<template>
  <div>
    <div class="flex flex-col items-start text-left mx-auto">
      <div class="text-UCPurple font-bold text-4xl mt-8 mb-8">
        {{ info.quiz_title }}
      </div>
    </div>
    <div
      class="cover-left grid grid-cols-1"
      :style="{ backgroundImage: `url(${info.Other.card_image})` }"
    >
      <div
        class="flex items-center text-left m-auto bg-white h-16 p-4 rounded-sm"
      >
        <div><i class="fas fa-clock fa-2x mr-2 text-UCPurple"></i></div>
        <div v-if="this.$store.state.quizTime != null">
          <AssignmentTimer v-if="!quizEnded" />
          <h2
            v-else-if="quizEnded && !bangla"
            class="text-UCPurple font-bold text-lg"
          >
            Assessment has ended !
          </h2>
          <h2
            v-else-if="quizEnded && bangla"
            class="text-UCYellow-dark font-bold text-lg"
          >
            কুইজ শেষ
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignmentTimer from '@/components/AssignmentTimer.vue'

export default {
  components: {
    AssignmentTimer,
  },
  computed: {
    quizEnded() {
      return this.$store.state.quizEnd
    },
    assigment() {
      return this.$store.state.assignmentType
    },
    info() {
      return this.$store.state.info
    },
    bangla() {
      return this.$store.state.bn
    },
  },
}
</script>
<style scoped>
.cover-left {
  background: #2465f3;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  position: relative;
}
@media (max-width: 767.98px) {
  .cover-left {
    height: 300px;
  }
}
@media (min-width: 1900px) {
  .cover-left {
    height: 300px;
  }
}
</style>
