import { render, staticRenderFns } from "./AnswerOptions.vue?vue&type=template&id=b4b0ae6c&scoped=true&"
import script from "./AnswerOptions.vue?vue&type=script&lang=js&"
export * from "./AnswerOptions.vue?vue&type=script&lang=js&"
import style0 from "./AnswerOptions.vue?vue&type=style&index=0&id=b4b0ae6c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4b0ae6c",
  null
  
)

export default component.exports