<template>
  <div class=""></div>
</template>

<script>
export default {
  name: 'LeaderBoard',
  //props: ["total", "userData"],
  computed: {
    userData() {
      return this.$store.state.leaderBoard
    },
    total() {
      return this.$store.state.ltotalScore
    },
    getQuizId() {
      return this.$store.state.current.quiz_id
    },
  },
  methods: {
    percentage(score) {
      let p = (score / this.total) * 100
      return Math.trunc(p)
    },
    highest() {
      return [].slice.call(arguments).sort(function (a, b) {
        return b - a
      })
    },
    showTime(time) {
      var a = time.split(':')
      var t = ''
      if (+a[0] > 0) {
        t += a[0] + 'h:'
      }
      if (+a[1] > 0) {
        t += a[1] + 'm:'
      }
      if (+a[2] > 0) {
        let s = (+a[2]).toFixed(2)
        t += s.toString() + 's'
      }
      return t
    },
  },
  created() {
    this.$store.dispatch('getLeaderBoard', this.getQuizId)
  },
}
</script>

<style scoped>
.table {
  border-spacing: 0 15px;
}

i {
  font-size: 1rem !important;
}

.table tr {
  border-radius: 20px;
}

tr td:nth-child(n + 5),
tr th:nth-child(n + 5) {
  border-radius: 0 0.625rem 0.625rem 0;
}

tr td:nth-child(1),
tr th:nth-child(1) {
  border-radius: 0.625rem 0 0 0.625rem;
}
.first {
  color: #f9a203;
}
.second {
  color: rgb(150, 146, 146);
}
.third {
  color: #cd7f32;
}
tbody:nth-child(odd) {
  background-color: #e5e7eb;
}
tbody:nth-child(even) {
  background-color: #fce7f3;
}

/* .table-bg:nth-child(odd) {
  background-color: #fecaca;
} */
@media only screen and (max-width: 700px) {
  /* .table-mobile {
    max-width: 350px;
  } */
}
</style>
