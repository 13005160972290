<template>
  <div>
    <div
      v-if="questions[current].question_type == 'MCQ'"
      class="flex flex-col justify-items-start"
    >
      <div
        :key="index"
        v-for="(option, index) in questions[current].options"
        @click="handleAnswerClick(index, option)"
        :class="{
          'bg-UCYellow-dark': clickedIndex == index,
          'text-white': clickedIndex == index,
        }"
        class="
          flex
          justify-between
          items-center
          bg-gray-200
          mt-1
          text-fontsm text-left
          p-4
          w-full
          options
          rounded
          tracking-tighter
          cursor-pointer
        "
      >
        {{ option }}
      </div>
    </div>
    <div
      v-else-if="questions[current].question_type == 'image'"
      class="grid grid-cols-1 sm:grid-cols-3 gap-2"
    >
      <div
        :key="index"
        v-for="(option, index) in questions[current].Other.images"
        @click="
          handleAnswerClick(
            index,
            getKeyByValue(questions[current].Other.images, option)
          )
        "
        class="cursor-pointer"
      >
        <img
          :src="option.imgURL"
          class="rounded-lg img-size"
          :class="{
            'active-img': clickedIndex == index,
          }"
          :alt="option.label"
        />
        <!-- {{ Object.keys(option)[index] }} -->
      </div>
    </div>
    <div v-else-if="questions[current].question_type == 'textbox'">
      <input
        type="text"
        class="
          focus:outline-none
          focus:ring-2 focus:ring-UCPink
          focus:border-2
          flex-1
          block
          w-full
          rounded
          sm:text-sm
          mt-2
          border-2 border-gray-400
          p-2
        "
        placeholder="your answer"
        v-model="currentAns"
      />
    </div>
    <div v-else-if="questions[current].question_type == 'textarea'">
      <textarea
        v-model="currentAns"
        rows="5"
        cols="50"
        class="
          focus:outline-none
          focus:ring-2 focus:ring-UCPink
          focus:border-2
          rounded
          sm:text-sm
          border-2 border-gray-400
          p-1
        "
        placeholder="Write your answer here"
      />
    </div>
    <div v-else-if="questions[current].question_type == 'file'">
      <Uploader @uploaded="uploaded" class="cursor:pointer" />
      <div v-if="isFileUploaded" class="text-left">*Succesfully uploaded</div>
    </div>
    <div class="flex flex-col justify-items-start w-full mt-4">
      <btn
        v-show="bangla"
        v-if="!showSubmit"
        @click="goNext()"
        class="
          bg-transparent
          text-UBlueDark
          font-extrabold
          mt-4
          w-full
          next-btn
        "
      >
        পরবর্তী
        <i class="fas fa-arrow-right"></i>
      </btn>
      <btn
        v-show="bangla"
        @click="goNext()"
        class="
          disabled:opacity-50
          bg-UCPink
          text-white
          mt-4
          w-full
          h-12
          transition
          duration-500
          ease-in-out
          hover:shadow-UCPink
          2xl:h-16
        "
        :disabled="!showSubmit"
        >জমা দিন</btn
      >
      <btn
        v-show="!bangla"
        v-if="!showSubmit"
        @click="goNext()"
        class="
          bg-transparent
          text-UBlueDark
          font-extrabold
          mt-4
          w-full
          next-btn
        "
      >
        next
        <i class="fas fa-arrow-right"></i>
      </btn>
      <btn
        v-show="!bangla"
        @click="goNext()"
        class="
          disabled:opacity-50
          bg-UCPink
          text-white
          mt-4
          w-full
          h-12
          transition
          duration-500
          ease-in-out
          hover:shadow-UCPink
          2xl:h-16
        "
        :disabled="!showSubmit"
        >Submit</btn
      >
      <!-- <pre>{{ this.$store.state.assignmentRes.data }}</pre> -->
      <!-- <pre>{{ this.answers }} </pre> -->
    </div>
    <btn
      v-show="!bangla"
      @click="showScore()"
      class="bg-transparent text-sm text-UBlue mt-4 w-full 2xl:w-3/4 lowercase"
    >
      *If you have already submitted, check your score here
      <i class="fas fa-arrow-right"></i>
    </btn>
    <btn
      v-show="bangla"
      @click="showScore()"
      class="bg-transparent text-sm text-UBlue mt-4 w-full 2xl:w-3/4 lowercase"
    >
      * আপনি যদি ইতিমধ্যে জমা দিয়ে থাকেন, এখানে আপনার স্কোর চেক করুন
      <i class="fas fa-arrow-right"></i>
    </btn>
  </div>
</template>

<script>
import btn from "@/components/BaseButton.vue";
import Uploader from "@/components/Uploader.vue";
export default {
  components: {
    btn,
    Uploader,
  },
  data() {
    return {
      isFileUploaded: false,
      option: "",
      clickedIndex: null,
      img: "../assets/img2.jpg",
      selectedAns: false,
      userAns: "",
      submitInfo: "",
      answers: [],
      currentAns: "",
      url: "https://upskill-files.s3-ap-southeast-1.amazonaws.com/",
    };
  },
  computed: {
    current() {
      return this.$store.state.currentQuestion;
    },
    questionId() {
      return this.$store.state.questions[this.current].question_id;
    },
    score() {
      return this.$store.state.userScore;
    },
    questions() {
      return this.$store.state.questions;
    },
    showSubmit() {
      return this.current + 1 == this.questions.length ? true : false;
    },
    subData() {
      return this.$store.state.assignmentRes.data;
    },
    bangla() {
      return this.$store.state.bn;
    },
  },
  methods: {
    uploaded(e) {
      this.url = this.url + e;
      //console.log(e);
      this.answers.push(this.url);
      this.isFileUploaded = true;
    },
    showScore() {
      this.$store.dispatch("resetThings");
      this.$router.push({
        path: `/assignmentScore/${this.subData.quiz.quiz_id}`,
      });
    },
    handleAnswerClick(index, userAns) {
      // this.option = isCorrect;
      this.selectedAns = true;
      this.userAns = userAns;
      this.storeIndex(index);
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },
    updateScore() {
      if (this.option) {
        this.$store.commit("incrementScore");
      }
    },
    goNext() {
      this.selectedAns = false;
      let nextQuestion = this.current + 1;
      if (nextQuestion < this.questions.length) {
        if (this.currentAns == "") {
          this.ErrorAlert("You have not answered");
        } else {
          this.answers.push(this.currentAns);
          this.currentAns = "";
          this.$store.commit("next");
        }
      } else {
        this.$store.state.quizEnd = true;
        this.subData.answers = this.answers;
        this.$upskill
          .usrSubmitAssignment(this.subData)
          .then((res) => {
            this.$store.dispatch("resetThings");
            this.SuccessAlert(res.data.message);
            // this.$router.push({
            //   path: `/assignmentScore/${this.subData.quiz.quiz_id}`,
            // });
          })
          .catch((res) => {
            if (res) {
              this.ErrorAlert(res.data.message);
              // this.$router.push({
              //   path: `/assignmentScore/${this.subData.quiz.quiz_id}`,
              // });
            } else {
              this.ErrorAlert("Couldn't submit");
            }
          });

        //this.$store.commit("storeUserRes", this.userAns);
        //this.$store.commit("showScore", true);
      }
    },
    storeIndex(index) {
      this.clickedIndex = index;
    },
  },
};
</script>

<style scoped>
.active-img {
  border: 2px solid #0062ff;
  border-radius: 0.5rem;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}
.img-size {
  display: block;
  width: 230px;
  height: 150px;
  /* object-fit: contain;
  width: 100%;
  height: 200px; */
}
.options {
  display: block;
  overflow: auto;
}
.next-btn {
  font-weight: bolder !important;
}
</style>
