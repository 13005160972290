<template>
  <div>
    <CHeader v-show="!bangla" />
    <CHeaderBn v-show="bangla" />
    <Quiz
      :items="questions"
      :info="info"
      :session="session"
      class="mb-24"
    ></Quiz>
    <Footer v-show="!bangla" class="fix" />
    <FooterBn v-show="bangla" class="fix" />
  </div>
</template>

<script>
// @ is an alias to /src
import Quiz from '@/components/QuizLayout.vue'
import Footer from '@/components/Footer.vue'
import FooterBn from '@/components/FooterBn.vue'
import CHeader from '@/components/CHeader.vue'
import CHeaderBn from '@/components/CHeaderBn.vue'
//import Swal from "sweetalert2";

export default {
  name: 'Home',
  components: {
    Quiz,
    Footer,
    CHeader,
    FooterBn,
    CHeaderBn,
  },
  data() {
    return {
      quizID: '',
      isStarted: false,
      questions: [],
      info: {},
      form: '',
      hasQuizStarted: false,
      session: {},
    }
  },
  computed: {
    showScore() {
      return this.$store.state.showScore
    },
    getForm() {
      return this.$store.state.form
    },
    getForm2() {
      return this.$store.state.pubStartForm
    },
    bangla() {
      return this.$store.state.bn
    },
  },
  methods: {
    quizStarted() {
      this.initiateQuiz()
      this.hasQuizStarted = true
    },
    getQuizzes() {
      this.$upskill.usrGetQuizzesByOrgID('upskill').then((res) => {
        this.info = res.data.data.find((o) => o.quiz_id == this.quizID)
        this.startQuizSession()
      })
    },
    startQuizSession() {
      this.$upskill.usrStartSession({ quiz_id: this.quizID }).then((res) => {
        res = res.data

        this.questions = res.data.questions
        this.$store.commit('setQuestion', this.questions)
        this.$store.state.info = res.data.quiz
        localStorage.setItem('session_data', res.data.session_data.session_id) //PUBLIC
        let d = new Date()
        let lastChar = res.data.quiz.time_required.split('').reverse()[0]
        switch (lastChar) {
          case 'm':
            d.setMinutes(d.getMinutes() + parseInt(res.data.quiz.time_required))
            break
          case 'h':
            d.setHours(d.getHours() + parseInt(res.data.quiz.time_required))
            break
          case 's':
            d.setSeconds(d.getSeconds() + parseInt(res.data.quiz.time_required))
            break
        }
        this.$store.state.quizTime = d.toISOString()
        if (res.data.records != null) {
          this.$store.state.currentQuestion = res.data.records.length
        }
        // let ques = this.questions.map((o) => ({
        //   id: o.question_id,
        //   other: o.Other,
        //   questionText: o.question_description,
        //   answerOptions: o.options.map((opt) => ({
        //     answerText: opt,
        //     isCorrect: true,
        //   })),
        // }));
        if (this.showScore) {
          this.$store.dispatch('resetThings')
        }

        this.$store.state.questions = this.questions
        localStorage.setItem('session_token', res.data.session_token)
        //console.log(res.data.data.session_token);
        this.$store.state.session = res.data.session_data
      })
    },
    startGuestSession() {
      this.getForm.quiz_id = this.quizID
      this.$upskill.guestStartSession(this.getForm).then((res) => {
        localStorage.setItem(
          'session_data',
          res.data.data.session_data.session_id
        ) //PUBLIC
        //res = res.data;
        //console.log(res);
        this.questions = res.data.questions
        this.$store.state.info = res.data.quiz
        let d = new Date()
        let lastChar = res.data.quiz.time_required.split('').reverse()[0]
        switch (lastChar) {
          case 'm':
            d.setMinutes(d.getMinutes() + parseInt(res.data.quiz.time_required))
            break
          case 'h':
            d.setHours(d.getHours() + parseInt(res.data.quiz.time_required))
            break
          case 's':
            d.setSeconds(d.getSeconds() + parseInt(res.data.quiz.time_required))
            break
        }
        this.$store.state.quizTime = d.toISOString()
        if (res.data.records != null) {
          this.$store.state.currentQuestion = res.data.records.length
        }
        if (this.showScore) {
          this.$store.dispatch('resetThings')
        }
        this.$store.state.questions = this.questions
        localStorage.removeItem('session')
        localStorage.setItem('session_token', res.data.session_token)
      })
    },
    startPublicSession() {
      this.getForm2.quiz_id = this.quizID
      this.$upskill.pubStartSession(this.getForm2).then((res) => {
        localStorage.setItem(
          'session_data',
          res.data.data.session_data.session_id
        ) //PUBLIC
        res = res.data
        //console.log(res);
        this.questions = res.data.questions
        this.$store.state.info = res.data.quiz
        this.$store.state.publicForm.session_id =
          res.data.session_data.session_id
        this.$store.commit('sessionID', this.$store.state.publicForm.session_id)

        let d = new Date()
        let lastChar = res.data.quiz.time_required.split('').reverse()[0]
        switch (lastChar) {
          case 'm':
            d.setMinutes(d.getMinutes() + parseInt(res.data.quiz.time_required))
            break
          case 'h':
            d.setHours(d.getHours() + parseInt(res.data.quiz.time_required))
            break
          case 's':
            d.setSeconds(d.getSeconds() + parseInt(res.data.quiz.time_required))
            break
        }
        this.$store.state.quizTime = d.toISOString()
        if (res.data.records != null) {
          this.$store.state.currentQuestion = res.data.records.length
        }
        if (this.showScore) {
          this.$store.dispatch('resetThings')
        }
        this.$store.state.questions = this.questions
        localStorage.removeItem('session')
        localStorage.setItem('session_token', res.data.session_token)
      })
    },
  },
  created() {
    //this.quizID = this.$route.params.id;
    this.quizID = this.$route.params.id
    this.$store.state.current.quiz_id = this.$route.params.id

    if (this.$route.query.token && this.$route.query.courseId) {
      let queryToken = this.$route.query.token
      let token = ''
      this.$upskill.decodeToken(queryToken).then((res) => {
        token = res.data.data
        localStorage.setItem('session', token)
        // this.getQuizzes();
        this.$store.state.isUser = true
        this.startQuizSession()
      })
      const courseId = this.$route.query.courseId
      const isVideoCurrent = this.$route.query.isVideoCurrent
      console.log(isVideoCurrent, 'is video current')
      this.$store.commit('setCourseIdFromIgc', courseId)
      if (isVideoCurrent === 'true') {
        this.$store.commit('setVideoCurrentOrNot', true)
        console.log('should not reun herer')
      } else {
        console.log('should run here')
        this.$store.commit('setVideoCurrentOrNot', false)
      }
    } else if (this.$route.query.public) {
      this.$store.state.isPublic = true
      this.startPublicSession()
    } else {
      this.$store.state.isGuest = true
      this.startGuestSession()
    }
  },
}
</script>
<style scoped>
.fix {
  min-height: 32vh;
}
</style>
