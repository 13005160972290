<template>
  <div class="container mx-auto">
    <Questions />
  </div>
</template>

<script>
import Questions from '@/components/QuesAnsA.vue'
export default {
  props: ['items', 'time'],
  components: {
    Questions,
  },
  data() {
    return {}
  },
  computed: {
    showReview() {
      return this.$store.state.showReview
    },
    isPublic() {
      return this.$store.state.isPublic
    },
    isGuest() {
      return this.$store.state.isGuest
    },
    isUser() {
      return this.$store.state.isUser
    },
    assigment() {
      return this.$store.state.assignmentType
    },
    showScore() {
      return this.$store.state.showScore
    },
    timeOver() {
      return this.$store.state.quizEnd
    },
  },
}
</script>
