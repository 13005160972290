<template>
  <div>
    <div class="bg-UCPurple p-4 rounded">
      <div v-if="percentage > 60">
        <div class="font-bold text-white text-lg text-4xl">
          <h2>You scored more than 60% you can watch the next video now</h2>
        </div>
      </div>
      <div v-else>
        <div class="font-bold text-white text-lg text-4xl">
          <h2>You scored less than 60% you need to watch the video again</h2>
        </div>
      </div>
    </div>

    <btn
      v-show="!isSubmitted"
      @click="handleResult()"
      class="
        bg-UCPink
        text-white
        mt-4
        shadow-UCPink
        w-full
        h-12
        transition
        duration-500
        ease-in-out
        hover:bg-UCDarkPink
        2xl:h-16
      "
      >Watch Video</btn
    >
    <div v-if="showReview" class="mt-6">
      <Review />
    </div>
  </div>
</template>

<script>
import btn from '@/components/BaseButton.vue'
import Review from '@/components/Review.vue'
import Swal from 'sweetalert2'
export default {
  // props: ["num", "total"],
  components: {
    btn,
    Review,
  },
  data() {
    return {
      samp: {},
      isSubmitted: false,
    }
  },
  computed: {
    percentage() {
      let p = (this.score / this.totalScore) * 100
      return Math.trunc(p)
    },
    showReview() {
      return this.$store.state.showReview
    },
    score() {
      return this.$store.state.userTotalScore
    },
    totalScore() {
      return this.$store.state.totalScore
    },
    bangla() {
      return this.$store.state.bn
    },
    publicForm() {
      return this.$store.state.publicForm
    },
    isPublic() {
      return this.$store.state.isPublic
    },
    getQuizId() {
      return this.$store.state.pubStartForm.quiz_id
    },
    courseId() {
      return this.$store.getters.getCourseIdFromIgc
    },
    videoCurrentOrNot() {
      return this.$store.getters.getIsVideoCurrent
    },
  },
  methods: {
    handleReview(payload) {
      console.log(payload)
      let sessionData = localStorage.getItem('session_data')
      this.$router.push('/report/' + sessionData)
    },
    handleResult() {
      const baseUrl = 'https://igc.startupdhaka.co/video'
      // const baseUrl = 'http://localhost:5173/video'

      console.log('handle result')
      if (this.videoCurrentOrNot) {
        console.log(this.videoCurrentOrNot)
        console.log('is inside')
        if (this.percentage > 60) {
          const url = `${baseUrl}/${this.courseId}?exam=1&isVideoCurrent=true`
          window.location.href = url
        } else {
          const url = `${baseUrl}/${this.courseId}?exam=0&isVideoCurrent=true`
          window.location.href = url
        }
      } else {
        if (this.percentage > 60) {
          const url = `${baseUrl}/${this.courseId}?exam=1&isVideoCurrent=true`
          window.location.href = url
        } else {
          const url = `${baseUrl}/${this.courseId}?exam=0&isVideoCurrent=true`
          window.location.href = url
        }
        // const url = `${baseUrl}/${this.courseId}?&isVideoCurrent=false`
        // window.location.href = url
      }
    },
    showPopup() {
      if (this.bangla && this.isPublic) {
        Swal.fire({
          title: 'আপনার উত্তর রিভিউ করতে ইউজারনেইম এবং ইমেইল টাইপ করুন',
          html: `<input type="text" id="name" class="swal2-input" placeholder="ইউজারনেইম">
          <input type="email" id="email" class="swal2-input" placeholder="ইমেইল">`,
          confirmButtonText: 'জমা দিন',
          focusConfirm: false,
          preConfirm: () => {
            const name = Swal.getPopup().querySelector('#name').value
            const email = Swal.getPopup().querySelector('#email').value
            if (!name || !email) {
              Swal.showValidationMessage(`Please enter your username`)
            }
            return { name: name, email: email }
          },
        }).then((result) => {
          //console.log(result.value.login);
          this.publicForm.username = result.value.name
          this.publicForm.email = result.value.email
          this.$upskill.pubSubmitDetails(this.publicForm).then((res) => {
            //this.Goto({ name: "PublicResults" });
            // this.samp = rs.data
            this.handleReview(res)
            this.$store.dispatch('getLeaderBoard', this.getQuizId)
          })
        })
      } else if (!this.bangla && this.isPublic) {
        Swal.fire({
          title: 'Please put your  username & email to review your answers',
          html: `<input type="text" id="name" class="swal2-input" placeholder="Username">
           <input type="email" id="email" class="swal2-input" placeholder="email">`,
          confirmButtonText: 'Submit',
          focusConfirm: false,
          preConfirm: () => {
            const name = Swal.getPopup().querySelector('#name').value
            const email = Swal.getPopup().querySelector('#email').value
            if (!name || !email) {
              Swal.showValidationMessage(`Please enter your username`)
            }
            const re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            let isValidMail = re.test(String(email).toLowerCase())
            if (!isValidMail) {
              Swal.showValidationMessage(`Please enter a valid email address!`)
            }
            return { name: name, email: email }
          },
        }).then((result) => {
          this.publicForm.username = result.value.name
          this.publicForm.email = result.value.email
          this.$upskill.pubSubmitDetails(this.publicForm).then((res) => {
            // this.samp = rs.data
            //this.Goto({ name: "PublicResults" });
            this.handleReview(res)
            this.isSubmitted = true
            this.$store.dispatch('getLeaderBoard', this.getQuizId)
          })
        })
      } else {
        this.handleReview({})
      }
    },
  },
}
</script>

<style></style>
