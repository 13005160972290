/* eslint-disable camelcase */
/* eslint-disable max-len  */
import request from "./utils";

const backendURL = "https://quizmsvc.upskill.com.bd";
//const backendURL = "http://localhost:9090";
const oldBackendURL = "https://backend.upskill.com.bd/api";
const BackendURL = "https://service.upskill.com.bd";
const org = {
  quiz: `${backendURL}/org/quiz`, //create, update
  quizByID: (id) => `${backendURL}/org/quiz/${id}`, //delete, get
  getQuizzesByOrgID: (id) => `${backendURL}/org/quizzes/${id}`,
  ques: `${backendURL}/org/question`, //create, update
  quesByID: (id) => `${backendURL}/org/question/${id}`, //delete, get
  getQuesByOrgID: (id) => `${backendURL}/org/quizzes/${id}`,
  quesSaveToQuiz: `${backendURL}/org/quiz/questions/save`,
  quesByQuizID: (id) => `${backendURL}/org/quiz/questions/${id}`,
  recordsByQuizID: (id) => `${backendURL}/org/records/${id}`,
};

const usr = {
  getQuizzesByOrgID: (id) => `${backendURL}/user/quizzes/${id}`,
  startQuizSession: `${backendURL}/user/session`,
  submitAnswer: `${backendURL}/user/submitanswer`,
  submitQuiz: `${backendURL}/user/submitquiz`,
  getQuestionsByQuizID: (id) => `${backendURL}/user/questions/${id}`,
  getAssignmentByID: (id) => `${backendURL}/user/assignment/${id}`,
  getAssignmentResultByID: (id) => `${backendURL}/user/results/${id}`,
  submitAssignment: `${backendURL}/user/submitassignment`,
};

const guest = {
  startQuizSession: `${backendURL}/guest/session`,
  submitAnswer: `${backendURL}/guest/submitanswer`,
  submitQuiz: `${backendURL}/guest/submitquiz`,
};
const pub = {
  startQuizSession: `${backendURL}/public/session`,
  submitAnswer: `${backendURL}/public/submitanswer`,
  submitQuiz: `${backendURL}/public/submitquiz`,
  submitDetails: `${backendURL}/public/submitdetails`,
};

const leaderBoard = {
  getLeaderBoard: (id) => `${backendURL}/public/leaderboard/${id}`,
};

const oldBackend = {
  loginUser: `${oldBackendURL}/login`,
};
const FP = {
  initial: `${BackendURL}/public/forget_password_request`,
  checkToken: `${BackendURL}/public/forget_password`,
  final: `${BackendURL}/public/pwd_request`,
};

export default new (function Upskill() {
  // ORG: CRUD: QUIZ
  this.orgAddQuiz = (body) => request.post(org.quiz, body);
  this.orgUpdateQuiz = (body) => request.put(org.quiz, body);
  this.orgGetQuizByID = (id) => request.get(org.quizByID(id));
  this.orgDeleteQuizByID = (id) => request.delete(org.quizByID(id));
  // ORG: CRUD: QUESTIONS
  this.orgAddQues = (body) => request.post(org.ques, body);
  this.orgUpdateQues = (body) => request.put(org.ques, body);
  this.orgGetQuesByID = (id) => request.get(org.quesByID(id));
  this.orgDeleteQuesByID = (id) => request.delete(org.quesByID(id));
  // ORG: OTHER
  this.orgGetQuizzes = (orgID) => request.get(org.getQuizzesByOrgID(orgID));
  this.orgSaveQuesToQuiz = (body) => request.post(org.quesSaveToQuiz, body);
  this.orgGetQuesByQuizID = (id) => request.get(org.quesByQuizID(id));
  this.orgGetQuizRecords = (id) => request.get(org.recordsByQuizID(id));
  // USR ROUTES
  this.usrGetQuizzesByOrgID = (orgID) =>
    request.get(usr.getQuizzesByOrgID(orgID));
  this.usrStartSession = (body) => request.post(usr.startQuizSession, body);
  this.usrGetQuesByQuizID = (id) => request.get(usr.getQuestionsByQuizID(id));
  this.usrSubmitAnswer = (body) => request.post(usr.submitAnswer, body);
  this.usrSubmitQuiz = (body) => request.post(usr.submitQuiz, body);
  this.usrSubmitAssignment = (body) => request.post(usr.submitAssignment, body);
  this.usrgetAssignmentByID = (id) => request.get(usr.getAssignmentByID(id));
  this.usrgetAssignmentResultByID = (id) =>
    request.get(usr.getAssignmentResultByID(id));
  //login
  this.loginUser = (body) => request.post(oldBackend.loginUser, body);
  //forget password
  this.pwInit = (email) => request.post(FP.initial, { email });
  this.decodeToken = (token) =>
    request.post("https://auth.upskill.com.bd/decode", {
      token: token,
    });
  //guest
  this.guestStartSession = (body) => request.post(guest.startQuizSession, body);
  this.guestSubmitAnswer = (body) => request.post(guest.submitAnswer, body);
  this.guestSubmitQuiz = (body) => request.post(guest.submitQuiz, body);
  //public
  this.pubStartSession = (body) => request.post(pub.startQuizSession, body);
  this.pubSubmitAnswer = (body) => request.post(pub.submitAnswer, body);
  this.pubSubmitQuiz = (body) => request.post(pub.submitQuiz, body);
  this.pubSubmitDetails = (body) => request.post(pub.submitDetails, body);
  //leaderBoard
  this.getLeaderBoard = (id) => request.get(leaderBoard.getLeaderBoard(id));

  this.contestStartSession = (jid) => request.post(`https://quizmsvc.upskill.com.bd/user/contest/startquiz/${jid}`, {});
  this.contestSubmitAnswer = (body) => request.post(`https://quizmsvc.upskill.com.bd/user/contest/submitanswer`, body)
  this.contestSubmitQuiz = (jid) => request.post(`https://quizmsvc.upskill.com.bd/user/contest/submitquiz/${jid}`, {})
})();
