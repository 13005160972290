<template>
  <div class="container mx-auto p-2" id="wrapper">
    <nav class="nav p-2 sm:p-4">
      <router-link to="/">
        <a href="#" class="logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="177"
            height="29.944"
            viewBox="0 0 177 29.944"
          >
            <g transform="translate(-478.85 106.927)">
              <g transform="translate(478.85 -106.927)">
                <g>
                  <path
                    d="M490.888-81.862a7.169,7.169,0,0,1-7.162-7.16v-8.155h2.687v8.155a4.481,4.481,0,0,0,4.475,4.476,4.481,4.481,0,0,0,4.475-4.476V-99.364H478.85v-7.563h24.077v2.686H481.537v2.192H498.05v13.027A7.169,7.169,0,0,1,490.888-81.862Z"
                    transform="translate(-478.85 106.927)"
                  />
                </g>
                <g transform="translate(0 4.879)">
                  <path
                    d="M490.888-77.249A12.054,12.054,0,0,1,478.85-89.287v-8.155h2.687v8.155a9.361,9.361,0,0,0,9.35,9.351,9.363,9.363,0,0,0,9.351-9.351v-13.027h2.687v13.027A12.054,12.054,0,0,1,490.888-77.249Z"
                    transform="translate(-478.85 102.314)"
                  />
                </g>
              </g>
              <g transform="translate(511.969 -100.868)">
                <path
                  d="M512.31-84.306a8.4,8.4,0,0,1-2.145-6.183v-10.459h3.441v10.327q0,5.456,4.713,5.455,4.686,0,4.687-5.455v-10.327H526.4v10.459a8.429,8.429,0,0,1-2.131,6.183,8.012,8.012,0,0,1-5.97,2.158A8.054,8.054,0,0,1,512.31-84.306Z"
                  transform="translate(-510.165 101.212)"
                />
                <path
                  d="M546.577-100.153a6.1,6.1,0,0,1,2.74,2.277,6.368,6.368,0,0,1,.953,3.521,6.388,6.388,0,0,1-.953,3.509,6.077,6.077,0,0,1-2.74,2.29,10.262,10.262,0,0,1-4.2.794H538.2v5.348h-3.443v-18.535h7.625A10.262,10.262,0,0,1,546.577-100.153Zm-.94,8.525a3.323,3.323,0,0,0,1.164-2.728,3.322,3.322,0,0,0-1.164-2.727,5.29,5.29,0,0,0-3.416-.953H538.2v7.36h4.024A5.29,5.29,0,0,0,545.637-91.627Z"
                  transform="translate(-508.748 101.212)"
                />
                <path
                  d="M559.393-82.743a8.648,8.648,0,0,1-3.163-1.615l1.191-2.675a9.373,9.373,0,0,0,2.794,1.47,10.144,10.144,0,0,0,3.322.569,5.745,5.745,0,0,0,3.085-.662,2.006,2.006,0,0,0,1.019-1.747,1.7,1.7,0,0,0-.569-1.311,4,4,0,0,0-1.444-.807q-.874-.292-2.383-.662a27.788,27.788,0,0,1-3.429-1.006,5.639,5.639,0,0,1-2.25-1.576,4.241,4.241,0,0,1-.94-2.9,4.948,4.948,0,0,1,.834-2.794,5.653,5.653,0,0,1,2.515-2,10.2,10.2,0,0,1,4.117-.741,13.313,13.313,0,0,1,3.337.424,9.215,9.215,0,0,1,2.832,1.217l-1.085,2.675a10.754,10.754,0,0,0-2.543-1.086,9.53,9.53,0,0,0-2.568-.37,5.367,5.367,0,0,0-3.032.689,2.125,2.125,0,0,0-.992,1.826,1.651,1.651,0,0,0,.569,1.3,4.111,4.111,0,0,0,1.443.794q.874.292,2.384.662a25.127,25.127,0,0,1,3.388.993,5.805,5.805,0,0,1,2.264,1.575,4.155,4.155,0,0,1,.939,2.86,4.888,4.888,0,0,1-.833,2.78,5.67,5.67,0,0,1-2.529,1.986,10.294,10.294,0,0,1-4.131.74A14.139,14.139,0,0,1,559.393-82.743Z"
                  transform="translate(-507.511 101.198)"
                />
                <path
                  d="M584.717-90.171l-2.938,3.018v4.739h-3.443v-18.535h3.443v9.506l9.16-9.506h3.867l-7.785,8.289,8.261,10.246h-4.024Z"
                  transform="translate(-506.237 101.212)"
                />
                <path
                  d="M601.453-100.948H604.9v18.535h-3.443Z"
                  transform="translate(-504.906 101.212)"
                />
                <path
                  d="M614.093-100.948h3.443v15.623h9.691v2.912H614.093Z"
                  transform="translate(-504.177 101.212)"
                />
                <path
                  d="M633.791-100.948h3.443v15.623h9.69v2.912H633.791Z"
                  transform="translate(-503.043 101.212)"
                />
              </g>
            </g>
          </svg>
        </a>
      </router-link>
      <div
        class="
          sm:flex
          sm:space-x-4
          sm:items-center
          uppercase
          font-heading
          text-sm
        "
        id="menu"
      >
        <router-link to="/">
          <a class="font-extrabold">Home</a>
        </router-link>
        <a class="font-extrabold" href="https://upskill.com.bd/workshop"
          >Training</a
        >
        <a class="font-extrabold" href="https://upskill.com.bd/library"
          >video Lessons</a
        >
        <a class="font-extrabold" href="https://upskill.com.bd/host_workshop"
          >Earn With upskill</a
        >
      </div>
      <div class="flex sm:items-center lg:-space-x-16" id="signup">
        <a
          class="font-extrabold text-sm uppercase sm:w-full text-UBlue"
          href=""
        >
          <i class="fas fa-user-circle fa-lg pr-1"></i>Sign In</a
        >

        <btn
          class="hidden sm:block bg-UBlue text-white text-sm shadow-UBlue w-1/2"
          >Sign up</btn
        >
      </div>
    </nav>
    <div>
      <i
        @click="showMobile()"
        class="sm:hidden fas fa-bars fa-2x"
        :class="{
          'mobile-menu': open,
          animated: open,
        }"
      ></i>
      <div
        class="hidden uppercase font-heading text-sm text-left"
        id="mobilemenu"
        :class="{
          'mobile-menu': open,
          animated: open,
        }"
      >
        <router-link to="/">
          <a class="font-extrabold">Home</a>
        </router-link>
        <a class="font-extrabold" href="https://upskill.com.bd/workshop"
          >Training</a
        >
        <a class="font-extrabold" href="https://upskill.com.bd/library"
          >video Lessons</a
        >
        <a class="font-extrabold" href="https://upskill.com.bd/host_workshop"
          >Earn With upskill</a
        >
        <a
          class="font-extrabold text-sm uppercase sm:w-full text-UBlue"
          href=""
        >
          <i class="fas fa-user-circle fa-lg pr-1"></i>Sign In</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import btn from "@/components/BaseButton.vue";
export default {
  components: {
    btn,
  },
  data: () => {
    return {
      open: false,
    };
  },
  methods: {
    clickMobileMenu() {
      this.opened = !this.opened;
      this.show = !this.show;
    },
    logout() {
      this.Logout();
      this.userLoggedIn = false;
    },
    showMobile() {
      this.open ? (this.open = false) : (this.open = true);
    },
  },
  created() {
  }
};
</script>
<style scoped>
.nav {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
}
#nav-item {
  font-weight: bold;
}
#menu .router-link-exact-active {
  color: #0062ff;
  cursor: pointer;
}
.icon {
  margin: 0;
  padding: 0;
}
.mobile-menu {
  opacity: 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: end;
}
.animated {
  animation: appear 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 767.98px) {
  .nav {
    grid-template-columns: 1fr;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: end;
    align-items: center;
  }
  #menu {
    display: none;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }
  #signup {
    display: none;
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center; */
  }
}
</style>
