<template>
  <button
    v-on="$listeners"
    class="p-2 rounded font-heading uppercase font-bold"
  >
    <slot />
  </button>
</template>

<script>
export default {};
</script>
