<template>
  <div class="flex justify-center min-h-screen table-mobile">
    <div class="sm:col-span-6">
      <div class="font-bold text-3xl">Leaderboard</div>
      <div class="overflow-auto lg:overflow-visible">
        <table
          class="
            table
            text-gray-400
            border-separate
            space-y-6
            text-sm
            sm:text-lg
          "
        >
          <thead class="bg-UCPurple text-gray-200">
            <tr>
              <th class="p-1 sm:p-3">Rank</th>
              <th class="p-1 sm:p-3"></th>
              <th class="p-1 sm:p-3">Name</th>
              <th class="p-1 sm:p-3">Score</th>
              <th class="p-1 sm:p-3">Time Taken</th>
            </tr>
          </thead>
          <tbody
            v-for="(user, index) in leaderboardData.slice(0, 10)"
            :key="user.session_id"
          >
            <tr>
              <td class="sm:p-3 text-gray-800">
                {{ index + 1 }}
              </td>
              <td class="sm:p-3">
                <span v-if="index === 0"
                  ><i class="fas fa-trophy fa-2x first"></i
                ></span>
                <span v-if="index === 1"
                  ><i class="fas fa-trophy fa-2x second"></i
                ></span>
                <span v-if="index === 2"
                  ><i class="fas fa-trophy fa-2x third"></i
                ></span>
              </td>
              <td class="sm:p-3">
                <div class="flex align-items-center">
                  <div class="ml-6 sm:ml-3">
                    <div class="text-gray-800">
                      {{ filterBadWord(user.username) }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="sm:p-3 text-gray-800">
                {{ user.total_score }}/{{ total }}
              </td>
              <td class="sm:p-3">
                <span class="text-UCPurple">{{ showTime(user.duration) }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeaderBoard',
  props: ['leaderboardData', 'total'],
  //props: ["total", "userData"],
  methods: {
    percentage(score) {
      let p = (score / this.total) * 100
      return Math.trunc(p)
    },
    highest() {
      return [].slice.call(arguments).sort(function (a, b) {
        return b - a
      })
    },
    filterBadWord(t) {
      console.log(t)
    },
    showTime(time) {
      var a = time.split(':')
      var t = ''
      if (+a[0] > 0) {
        t += a[0] + 'h:'
      }
      if (+a[1] > 0) {
        t += a[1] + 'm:'
      }
      if (+a[2] > 0) {
        let s = (+a[2]).toFixed(2)
        t += s.toString() + 's'
      }
      return t
    },
  },
}
</script>

<style scoped>
.table {
  border-spacing: 0 15px;
}

i {
  font-size: 1rem !important;
}

.table tr {
  border-radius: 20px;
}

tr td:nth-child(n + 5),
tr th:nth-child(n + 5) {
  border-radius: 0 0.625rem 0.625rem 0;
}

tr td:nth-child(1),
tr th:nth-child(1) {
  border-radius: 0.625rem 0 0 0.625rem;
}
.first {
  color: #f9a203;
}
.second {
  color: rgb(150, 146, 146);
}
.third {
  color: #cd7f32;
}
tbody:nth-child(odd) {
  background-color: #e5e7eb;
}
tbody:nth-child(even) {
  background-color: #fce7f3;
}

/* .table-bg:nth-child(odd) {
  background-color: #fecaca;
} */
@media only screen and (max-width: 700px) {
  /* .table-mobile {
    max-width: 350px;
  } */
}
</style>
