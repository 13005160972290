<template>
  <div>
    <Header />
    <Quiz />
    <Footer class="mt-2" />
  </div>
</template>

<script>
// @ is an alias to /src
import Quiz from "@/components/QuizLayout.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  name: "Home",
  components: {
    Quiz,
    Footer,
    Header,
  },
  created() {
    this.$router.push({ path: "/login" });
  },
};
</script>
