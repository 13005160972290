<template>
  <div>
    <div
      v-if="questions[current].question_type == 'MCQ'"
      class="flex flex-col justify-items-start"
    >
      <div
        :key="index"
        v-for="(option, index) in questions[current].options"
        @click="handleAnswerClick(index, option)"
        :class="{
          'bg-UCYellow-dark': clickedIndex == index,
          'text-white': clickedIndex == index,
        }"
        class="
          md:max-w-screen-md
          flex
          justify-between
          items-center
          options
          cursor-pointer
          border border-gray-100
          py-3
          my-2
          text-black
          font-semibold
          text-xl
          bg-gray-200
          rounded-lg
        "
      >
        {{ option }}
      </div>
      <!-- <img src="https://picsum.photos/400/300" alt="" />
      <img src="https://picsum.photos/400/300" alt="" /> -->
    </div>
    <div
      v-else-if="questions[current].question_type == 'image'"
      class="grid grid-cols-1 sm:grid-cols-3 gap-2"
    >
      <div
        :key="index"
        v-for="(option, index) in questions[current].Other.images"
        @click="
          handleAnswerClick(
            index,
            getKeyByValue(questions[current].Other.images, option)
          )
        "
        class="cursor-pointer"
      >
        <img
          :src="option.imgURL"
          class="rounded-lg img-size"
          :class="{
            'active-img': clickedIndex == index,
          }"
          :alt="option.label"
        />
        <!-- {{ Object.keys(option)[index] }} -->
      </div>
    </div>

    <div class="flex flex-col justify-items-start w-full mt-4">
      <btn
        v-if="!showSubmit"
        :disabled="!selectedAns"
        @click="goNext()"
        class="
          bg-blueDs
          disabled:opacity-50
          text-white
          mt-4
          w-full
          h-12
          transition
          duration-500
          ease-in-out
          hover:bg-blueDs
          2xl:h-16
        "
      >
        next
        <i class="fas fa-arrow-right"></i>
      </btn>
      <btn
        v-show="showSubmit"
        @click="goNext()"
        class="
          disabled:opacity-50
          bg-blueDs
          text-white
          mt-4
          w-full
          h-12
          transition
          duration-500
          ease-in-out
          hover:bg-UCDarkPink
          2xl:h-16
        "
        :disabled="!showSubmit"
        >Submit</btn
      >
    </div>
  </div>
</template>

<script>
import btn from '@/components/BaseButton.vue'
export default {
  components: {
    btn,
  },
  data() {
    return {
      option: '',
      clickedIndex: null,
      img: '../assets/img2.jpg',
      selectedAns: false,
      userAns: '',
      submitInfo: '',
    }
  },
  computed: {
    current() {
      return this.$store.state.currentQuestion
    },
    questionId() {
      return this.$store.state.questions[this.current].question_id
    },
    score() {
      return this.$store.state.userScore
    },
    questions() {
      return this.$store.state.questions
    },
    showSubmit() {
      return this.current + 1 == this.questions.length ? true : false
    },
    publicForm() {
      return this.$store.state.publicForm
    },
  },
  methods: {
    handleAnswerClick(index, userAns) {
      // this.option = isCorrect;
      this.selectedAns = true
      this.userAns = userAns
      this.storeIndex(index)
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value)
    },
    updateScore() {
      if (this.option) {
        this.$store.commit('incrementScore')
      }
    },
    goNext() {
      this.selectedAns = false
      let nextQuestion = this.current + 1
      if (nextQuestion < this.questions.length) {
        //console.log("inside next");
        //this.$store.dispatch("submitAns", this.questionId, this.userAns);
        let args = {
          question_id: this.questionId,
          answer: this.userAns,
          token: localStorage.getItem('session'),
        }
        //console.log(this.userAns);
        if (this.$store.state.isGuest) {
          this.$upskill
            .guestSubmitAnswer(args)
            .then(() => {
              //this.submitInfo = res.data;
              //this.$store.commit("submitAns", this.submitInfo);
              this.userAns = ''
              this.clickedIndex = null
              this.$store.commit('next')
            })
            .catch(() => {
              this.ErrorAlert("Couldn't submit!")
            })
        } else if (this.$store.state.isPublic) {
          this.$upskill
            .pubSubmitAnswer(args)
            .then(() => {
              //this.submitInfo = res.data;
              //this.$store.commit("submitAns", this.submitInfo);
              this.userAns = ''
              this.clickedIndex = null
              this.$store.commit('next')
            })
            .catch(() => {
              this.ErrorAlert("Couldn't submit!")
            })
        } else {
          this.$upskill
            .usrSubmitAnswer(args)
            .then(() => {
              this.userAns = ''
              this.clickedIndex = null
              this.$store.commit('next')
            })
            .catch(() => {
              this.ErrorAlert("Couldn't submit!")
            })
        }
      } else {
        //this.$store.commit("setResponse", this.clickedIndex);
        this.$store.state.quizEnd = true
        let args = {
          question_id: this.questionId,
          answer: this.userAns,
          token: localStorage.getItem('session'),
        }
        let args1 = {
          token: localStorage.getItem('session'),
        }
        if (this.$store.state.isGuest) {
          this.$upskill.guestSubmitAnswer(args).then((res) => {
            this.submitInfo = res.data
            //this.$store.commit("submitAns", this.submitInfo);
            this.$upskill.guestSubmitQuiz(args1).then((res) => {
              //this.submitInfo = res.data;
              this.$store.commit('submitQuiz', res.data)
              for (var i = 0; i < res.data.record_data.length; i++) {
                this.$store.state.actualResponse.push(
                  res.data.record_data[i].actual_answer
                )
                this.$store.state.userResponse.push(
                  res.data.record_data[i].user_answers
                )
              }
            })
          })
          this.$store.commit('showScore', true)
        } else if (this.$store.state.isPublic) {
          this.$upskill.pubSubmitAnswer(args).then((res) => {
            this.submitInfo = res.data
            //this.$store.commit("submitAns", this.submitInfo);
            this.$upskill.pubSubmitQuiz(args1).then((res) => {
              //this.submitInfo = res.data;
              localStorage.setItem('score_data', res.data.user_total_score)
              this.$store.commit('submitQuiz', res.data)
              for (var i = 0; i < res.data.record_data.length; i++) {
                this.$store.state.actualResponse.push(
                  res.data.record_data[i].actual_answer
                )
                this.$store.state.userResponse.push(
                  res.data.record_data[i].user_answers
                )
              }
            })
          })

          this.$store.commit('showScore', true)
          this.Goto({ name: 'PublicResultsA' })
        } else {
          this.$upskill.usrSubmitAnswer(args).then((res) => {
            this.submitInfo = res.data
            //this.$store.commit("submitAns", this.submitInfo);
            this.$upskill.usrSubmitQuiz(args1).then((res) => {
              //this.submitInfo = res.data;
              this.$store.commit('submitQuiz', res.data)
              for (var i = 0; i < res.data.record_data.length; i++) {
                this.$store.state.actualResponse.push(
                  res.data.record_data[i].actual_answer
                )
                this.$store.state.userResponse.push(
                  res.data.record_data[i].user_answers
                )
              }
            })
          })

          //this.$store.commit("storeUserRes", this.userAns);
          this.$store.commit('showScore', true)
        }
      }
    },
    storeIndex(index) {
      this.clickedIndex = index
    },
  },
}
</script>

<style scoped>
.active-img {
  border: 2px solid #0062ff;
  border-radius: 0.5rem;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}
.img-size {
  display: block;
  width: 230px;
  height: 150px;
}
.options {
  display: block;
  overflow: auto;
}
.next-btn {
  font-weight: bolder !important;
}
</style>
