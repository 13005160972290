<template>
  <div class="flex flex-col">
    <div
      v-show="!bangla"
      class="
        font-heading
        text-UCPurple
        font-bold
        text-2xl
        2xl:text-3xl
        text-left
      "
    >
      Review your answers
    </div>
    <div
      v-show="bangla"
      class="font-heading font-bold text-2xl 2xl:text-3xl text-left"
    >
      আপনার উত্তর রিভিউ করুন
    </div>
    <br />
<!--    {{ this.$store.state.actualResponse }} &ndash;&gt;-->
    <div>
      <div v-for="(q, idx) in questions" :key="idx">
        <div class="text-xl 2xl:text-2xl font-bold text-left mx-auto mt-4">
          {{ idx + 1 }}.{{ q.question_description }}
        </div>
        <br />
        <div
          v-if="q.question_type == 'MCQ'"
          class="flex flex-col justify-items-start"
        >
          <div
            :key="index"
            v-for="(option, index) in q.options"
            :class="{
              'wrong': option == userAns[idx] && userAns[idx] != actualAns[idx],
              'right':
                (option == userAns[idx] && userAns[idx] == actualAns[idx]) ||
                option == actualAns[idx],
            }"
            class="
              flex
              justify-between
              items-center
              bg-gray-200
              mt-1
              text-fontsm text-left
              p-4
              w-full
              options
              rounded
              tracking-tighter
            "
          >
            {{ option }}
            <i
              v-if="
                (option == userAns[idx] && userAns[idx] == actualAns[idx]) ||
                option == actualAns[idx]"
              class="fas fa-check-circle tick"
            ></i>
            <i
              v-if="option == userAns[idx] && userAns[idx] != actualAns[idx]"
              class="fas fa-times-circle cross"
            ></i>
          </div>
        </div>
        <div
          v-else-if="q.question_type == 'image'"
          class="grid grid-cols-1 sm:grid-cols-3 gap-2"
        >
          <div :key="index" v-for="(option, index) in q.Other.images">
            <img
              :src="option.imgURL"
              class="rounded-lg img-size"
              :class="{
                'wrong-img':
                  userAns[idx] == index && userAns[idx] != actualAns[idx],
                'correct-img':
                  (userAns[idx] == index && userAns[idx] == actualAns[idx]) ||
                  index == actualAns[idx],
              }"
              alt="answer option"
            />
            <!-- {{ index }} -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import btn from "@/components/BaseButton.vue";
export default {
  components: {
    //btn,
  },
  data() {
    return {};
  },
  computed: {
    questions() {
      return this.$store.state.questions;
    },
    userAns() {
      return this.$store.state.userResponse;
    },
    actualAns() {
      return this.$store.getters.getActualAns;
    },
    bangla() {
      return this.$store.state.bn;
    },
  },
  methods: {
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },
  },
};
</script>

<style scoped>
.correct-img {
  border: 4px solid #60d91a;
  border-radius: 0.5rem;
}
.wrong-img {
  border: 4px solid #d60000;
  border-radius: 0.5rem;
}
.img-size {
  display: block;
  width: 230px;
  height: 150px;
}
.options {
  display: block;
  overflow: auto;
}
.wrong {
  background-color: #d2060519;
  color: #d20605;
}
.right {
  background-color: #23af4c19;
  color: #23af4c;
}
.normal {
  background-color: #efebf3;
}
.tick {
  color: #23af4c;
}
.cross {
  color: #d20605;
}
</style>
