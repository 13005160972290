import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    courseIdFromIgc: '',
    userResponse: [],
    isVideoCurrent: false,
    alreadyAns: [],
    actualResponse: [],
    totalScore: 0,
    showReview: false,
    currentQuestion: 0,
    userScore: 0,
    showScore: false,
    questions: [],
    userTotalScore: null,
    form: {
      quiz_id: '',
      email: '',
      username: '',
    },
    pubStartForm: {
      quiz_id: '',
    },
    current: {
      quiz_id: '',
    },
    publicForm: {
      session_id: '',
      username: '',
      email: '',
    },
    isGuest: false,
    isPublic: false,
    isUser: false,
    quizTime: null,
    quizEnd: false,
    assignmentType: false,
    assignmentRes: '',
    info: '',
    session: {},
    bn: false,
    leaderBoard: '',
    ltotalScore: '',
    sessionID: '',
  },
  getters: {
    getActualAns(state) {
      return state.actualResponse
    },
    getUserAns(state) {
      return state.userResponse
    },
    getCourseIdFromIgc(state) {
      return state.courseIdFromIgc
    },
    getIsVideoCurrent(state) {
      return state.isVideoCurrent
    },
  },
  mutations: {
    setQuestion(state, payload) {
      state.questions = payload
    },
    setCourseIdFromIgc(state, payload) {
      state.courseIdFromIgc = payload
    },
    setVideoCurrentOrNot(state, payload) {
      state.isVideoCurrent = payload
    },
    next(state) {
      state.currentQuestion++
    },
    resetInfo(state) {
      state.info = ''
    },
    sessionID(state, payload) {
      state.sessionID = payload
    },
    calculateTotal(state, score) {
      state.totalScore += score
    },
    showScore(state, payload) {
      state.showScore = payload
    },
    setResponse(state, idx) {
      state.userResponse.push(idx)
    },
    showReview(state, payload) {
      state.showReview = payload
    },
    submitAns(state, payload) {
      state.actualResponse.push(payload.answer)
    },
    submitQuiz(state, payload) {
      state.userTotalScore = payload.user_total_score
      state.totalScore = payload.quiz_score
    },
    storeUserRes(state, payload) {
      state.userResponse.push(payload)
    },
    resetCurrent(state) {
      state.currentQuestion = 0
    },
    resetResponse(state) {
      state.userResponse = []
      state.actualResponse = []
    },
    resetForm(state) {
      state.form.quiz_id = ''
      // state.form.name = "";
      // state.form.email = "";
    },
    resetTime(state) {
      state.quizTime = 0
      state.quizEnd = true
    },
    resetType(state) {
      state.assignmentType = false
    },
    resetUser(state) {
      state.isGuest = false
      state.isPublic = false
      state.isUser = false
    },
    resetLang(state) {
      state.bn = false
    },
    setLeaderBoard(state, payload) {
      state.leaderBoard = payload.data.data
      state.ltotalScore = payload.data.quiz_score
    },
  },
  actions: {
    getLeaderBoard({ commit }, quizId) {
      // that.$upskill.getLeaderBoard(quizId).then((res) => {
      //   commit("setLeaderBoard", res);
      // });
      axios
        .get(`https://quizmsvc.upskill.com.bd/public/leaderboard/${quizId}`)
        .then((res) => {
          commit('setLeaderBoard', res)
        })
    },
    resetThings({ commit }) {
      commit('showScore', false)
      commit('showReview', false)
      commit('resetCurrent')
      commit('resetResponse')
      commit('resetForm')
      commit('resetTime')
      commit('resetType')
      commit('resetUser')
      commit('resetLang')
      commit('resetInfo')
    },
  },
  modules: {},
})
