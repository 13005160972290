import alert from "sweetalert2";

// All the mixin methods shall start with capital letter
// Mixins must contain methods those are widely used

const mixins = {
  methods: {
    ErrorAlert: (msg) => {
      alert.fire({
        text: msg,
        target: "#error-alert",
        icon: "error",
        iconColor: "#e20000",
        animation: false,
        showConfirmButton: false,
        showCloseButton: true,
        allowOutsideClick: true,
        customClass: {
          popup: "colored-toast",
          content: "error-alert-text",
          timer: 3000,
        },
        toast: true,
        position: "center",
      });
      // alert.fire("Oops!", msg, "error");
    },
    SuccessAlert: (msg) => {
      alert.fire({
        text: msg,
        timer: 3000,
        target: "#success-alert",
        icon: "success",
        iconColor: "#23af4c",
        animation: false,
        showConfirmButton: false,
        showCloseButton: true,
        allowOutsideClick: true,
        customClass: {
          popup: "colored-toast",
          content: "success-alert-text",
        },
        toast: true,
        position: "center",
      });
    },
    TimerAlert: (title, msg, timer) => {
      alert.fire({
        title: title,
        html: msg,
        timer: timer,
        timerProgressBar: true,
      });
    },
    Alert: (title, message, type) => {
      alert.fire(title, message, type);
    },
    /**
     * @return {number}
     */
    WordTimeCount(len) {
      return Math.round(len.length / 800);
    },
    GotoBlogByID(id) {
      this.$router.push({ name: "lessonsp", params: { id } });
    },
    Goto(obj) {
      this.$router.push(obj);
    },
    GotoWS(id) {
      this.$router.push({ name: "workshop", params: { workshopId: id } });
    },
    GotoProfile() {
      this.$router.push({ name: "user_profile" });
    },
    GotoVid(id) {
      let vid = id + "";
      this.$router.push({ name: "lessons", params: { id: vid } });
    },
    Loaded() {
      this.$store.state.loaded = true;
    },
    /**
     * @return {boolean}
     */
    SignedIn() {
      let token = localStorage.getItem("session");
      return !!token;
    },
    RemoveToken() {
      localStorage.removeItem("session");
      localStorage.removeItem("userInfo");
    },
    LocalStore(key, obj) {
      localStorage.setItem(key, JSON.stringify(obj));
    },
    PendingWorkshop() {
      return localStorage.getItem("pendingWorkshop");
    },
    PendingFirstTimer() {
      return localStorage.getItem("firstTimer");
    },
    GotoHome() {
      this.$router.push({ name: "home" });
    },
    SetFirstTimer() {
      localStorage.setItem("firstTimer", "true");
    },
    RemoveFirstTimer() {
      localStorage.removeItem("firstTimer");
    },
    GetPendingInvoice() {
      return localStorage.getItem("pendingInvoicePage");
    },
    SetPendingInvoice() {
      localStorage.setItem("pendingInvoicePage", "subscription");
    },
    RemovePendingInvoice() {
      localStorage.removeItem("pendingInvoicePage");
    },
    Logout() {
      this.RemoveToken();
      this.Goto({ name: "LogIn" });
    },
    RemoveStyle(attr) {
      let dom = document.createElement("div");
      dom.innerHTML = attr;
      let elems = [
        "p",
        "h1",
        "h2",
        "span",
        "div",
        "h3",
        "h4",
        "h5",
        "u",
        "ul",
        "li",
      ];
      elems.forEach((e) => {
        let target = dom.querySelectorAll(e);
        Array.prototype.forEach.call(target, function (element) {
          element.removeAttribute("style");
          element.style.fontStyle = "Open Sans; sans-serif";
        });
      });
      elems.forEach((e) => {
        let target = dom.querySelectorAll(e);
        Array.prototype.forEach.call(target, function (element) {
          switch (e) {
            case "p":
              element.style.fontSize = "16px";
              // element.style.margin = "0"
              break;
            case "h1":
              element.style.fontSize = "18px";
              break;
            case "li":
              element.style.marginLeft = "50px";
              element.style.fontSize = "14px";
              break;
          }
        });
      });
      return dom.innerHTML;
    },
  },
};
export default mixins;
