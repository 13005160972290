<template>
  <div class="flex flex-col">
    <div
      class="
        font-heading
        text-UCPurple
        font-bold
        text-2xl
        2xl:text-3xl
        text-left
        my-6
      "
    >
      Review Your Answers
    </div>
    <br />
    <!--    {{ this.$store.state.actualResponse }} &ndash;&gt;-->
    <div>
      <div v-for="(q, idx) in data" :key="idx">
        <div class="text-xl 2xl:text-2xl font-bold text-left mx-auto mt-4">
          {{ idx + 1 }}.{{ q.question_description }}
        </div>
        <br />
        <!--          v-if="q.question_type == 'MCQ'" check later-->

        <div class="flex flex-col justify-items-start">
          <div
            :key="index"
            v-for="(option, index) in q.options"
            class="
              flex
              justify-between
              items-center
              bg-gray-200
              mt-1
              text-fontsm text-left
              p-4
              w-full
              options
              rounded
              tracking-tighter
            "
            style="display: flex"
            :class="{
                wrong: option == q.user_answer && q.user_answer != q.actual_answer,
                right:
                (option == q.user_answer && q.user_answer == q.actual_answer) ||
                option == q.actual_answer,
                }"
          >
            {{ option }}
                        <i
                          v-if="
                            (option == q.actual_answer && q.user_answer == q.actual_answer) ||
                            option == q.actual_answer
                          "
                          class="fas fa-check-circle tick"
                        ></i>
                        <i
                          v-if="option == q.user_answer && q.user_answer != q.actual_answer"
                          class="fas fa-times-circle cross"
                        ></i>
          </div>
        </div>
        <!--        <div-->
        <!--          v-else-if="q.question_type == 'image'"-->
        <!--          class="grid grid-cols-1 sm:grid-cols-3 gap-2"-->
        <!--        >-->
        <!--          <div :key="index" v-for="(option, index) in q.Other.images">-->
        <!--            <img-->
        <!--              :src="option.imgURL"-->
        <!--              class="rounded-lg img-size"-->
        <!--              :class="{-->
        <!--                'wrong-img':-->
        <!--                  userAns[idx] == index && userAns[idx] != actualAns[idx],-->
        <!--                'correct-img':-->
        <!--                  (userAns[idx] == index && userAns[idx] == actualAns[idx]) ||-->
        <!--                  index == actualAns[idx],-->
        <!--              }"-->
        <!--              alt="answer option"-->
        <!--            />-->
        <!--            &lt;!&ndash; {{ index }} &ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
//import btn from "@/components/BaseButton.vue";
export default {
  props: ["data"],
  components: {
    //btn,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.correct-img {
  border: 4px solid #60d91a;
  border-radius: 0.5rem;
}
.wrong-img {
  border: 4px solid #d60000;
  border-radius: 0.5rem;
}
.img-size {
  display: block;
  width: 230px;
  height: 150px;
}
.options {
  display: unset;
  overflow: auto;
}
.wrong {
  background-color: #d2060519;
  color: #d20605;
}
.right {
  background-color: #23af4c19;
  color: #23af4c;
}
.normal {
  background-color: #efebf3;
}
.tick {
  color: #23af4c;
}
.cross {
  color: #d20605;
}
</style>
