var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"\n      font-heading\n      text-UCPurple\n      font-bold\n      text-2xl\n      2xl:text-3xl\n      text-left\n      my-6\n    "},[_vm._v(" Review Your Answers ")]),_c('br'),_c('div',_vm._l((_vm.data),function(q,idx){return _c('div',{key:idx},[_c('div',{staticClass:"text-xl 2xl:text-2xl font-bold text-left mx-auto mt-4"},[_vm._v(" "+_vm._s(idx + 1)+"."+_vm._s(q.question_description)+" ")]),_c('br'),_c('div',{staticClass:"flex flex-col justify-items-start"},_vm._l((q.options),function(option,index){return _c('div',{key:index,staticClass:"\n            flex\n            justify-between\n            items-center\n            bg-gray-200\n            mt-1\n            text-fontsm text-left\n            p-4\n            w-full\n            options\n            rounded\n            tracking-tighter\n          ",class:{
              wrong: option == q.user_answer && q.user_answer != q.actual_answer,
              right:
              (option == q.user_answer && q.user_answer == q.actual_answer) ||
              option == q.actual_answer,
              },staticStyle:{"display":"flex"}},[_vm._v(" "+_vm._s(option)+" "),(
                          (option == q.actual_answer && q.user_answer == q.actual_answer) ||
                          option == q.actual_answer
                        )?_c('i',{staticClass:"fas fa-check-circle tick"}):_vm._e(),(option == q.user_answer && q.user_answer != q.actual_answer)?_c('i',{staticClass:"fas fa-times-circle cross"}):_vm._e()])}),0)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }