b
<template>
  <div class="container mx-auto px-2 sm:px-0">
    <div class="flex flex-col-reverse sm:grid grid-cols-2 p-1 gap-4">
      <div v-if="isUser && (showScore || timeOver)">
        <div class="mt-4 sm:mt-0 w-full place-self-start">
          <LeaderBoard />
        </div>
      </div>
      <div
        v-else
        class="w-full sm:w-3/4 mt-12"
        :class="{
          'place-self-start': showReview,
          'mx-auto': showReview,
        }"
      >
        <!--        <div-->
        <!--            v-else-->
        <!--            class="w-full sm:w-3/4"-->
        <!--            :class="{-->
        <!--          'place-self-end': !showReview,-->
        <!--          'place-self-start': showReview,-->
        <!--          'mx-auto': showReview,-->
        <!--        }"-->
        <!--        >-->
        <leftCover class="rounded-lg" />
      </div>
      <div
        class="grid grid-cols-1 gap-2 place-items-start mt-8 lg:w-3/4 2xl:w-3/5"
      >
        <Questions />
      </div>
    </div>
  </div>
</template>

<script>
import leftCover from '@/components/LeftSection.vue'
import Questions from '@/components/QuesAns.vue'
import LeaderBoard from '@/components/LeaderBoard.vue'
export default {
  props: ['items', 'time'],
  components: {
    leftCover,
    Questions,
    LeaderBoard,
  },
  data() {
    return {}
  },
  created() {},
  computed: {
    showReview() {
      return this.$store.state.showReview
    },
    isPublic() {
      return this.$store.state.isPublic
    },
    isGuest() {
      return this.$store.state.isGuest
    },
    isUser() {
      return this.$store.state.isUser
    },
    assigment() {
      return this.$store.state.assignmentType
    },
    showScore() {
      return this.$store.state.showScore
    },
    timeOver() {
      return this.$store.state.quizEnd
    },
  },
}
</script>
