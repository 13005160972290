import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Quiz from '../views/Quiz'
import Assignment from '../views/Assignment.vue'
import Assessment from '../views/Assessment.vue'
import Contest from '../views/Contest.vue'
import DataScience from '../views/DataScience'
import Report from '../views/Report'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/report/:id',
    name: 'Report',
    component: Report,
  },
  {
    path: '/quiz/:id',
    name: 'quiz',
    component: Quiz,
  },
  {
    path: '/contest/:id',
    name: 'contest',
    component: Contest,
  },
  {
    path: '/assignment/:id',
    name: 'Assignment',
    component: Assignment,
  },
  {
    path: '/assessment/:id',
    name: 'Assessment',
    component: Assessment,
  },
  {
    path: '/datascience/:id',
    name: 'datascience',
    component: DataScience,
  },
  // {
  //   path: '/contest/:id',
  //   name: 'contest',
  //   component: Assessment,
  // },
  {
    path: '/guest/:id',
    name: 'FormPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/FormPage'),
  },
  {
    path: '/leaderboard/:id',
    name: 'LeaderBoard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/LeaderBoard'),
  },
  {
    path: '/assignmentScore/:id',
    name: 'AssignmentScore',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AssignmentScore'),
  },
  {
    path: '/login',
    name: 'LogIn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LogIn'),
  },
  {
    path: '/publicresults',
    name: 'PublicResults',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PublicResults'),
  },
  {
    path: '/result',
    name: 'PublicResultsA',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PublicResultsA'),
  },
  {
    path: '/user',
    name: 'usrDash',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UsrDash'),
  },
  {
    path: '/org',
    name: 'orgDash',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrgDash'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
