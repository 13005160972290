<template>
  <section class="footer bg-black text-white text-left p-2 lg:p-4 lg:mt-4">
    <div class="container mx-auto">
      <div class="grid grid-cols-1 sm:grid-cols-4 place-items-start gap-1">
        <div class="col-md-4">
          <h3 class="text-lg font-bold uppercase mb-4 font-heading">
            আমাদের সম্পর্কে
          </h3>
          <p class="text-sm">
            আপস্কিল ক্লাসরুম একটি অনলাইন লার্নিং প্ল্যাটফর্ম যা বাংলাদেশের ১ম-
            ৭ম শ্রেণির তরুণ শিক্ষার্থীদের জন্য সমৃদ্ধ শিক্ষা প্রদান করে। আপস্কিল
            ইনোভেটিভ কনটেন্ট এবং দক্ষ শিক্ষকদের মাধ্যমে এই সেবা নিশ্চিত করে।
          </p>
          <div class="sm:flex">
            <img class="mt-1" src="../assets/ict.png" alt />
            <img class="mt-1" src="../assets/holon.png" alt />
            <img class="mt-1" src="../assets/stbd.png" alt />
          </div>
        </div>
        <div class="col-md-2">
          <h3 class="text-lg font-bold uppercase mb-4 font-heading">লিঙ্ক</h3>
          <ul class="text-sm text-UGreen-light">
            <li>
              <a class="" href="https://upskill.com.bd/faq">FAQ </a>
            </li>
            <li>
              <a class="hover-link" href="https://upskill.com.bd/privacy"
                >Terms and Conditions</a
              >
            </li>
            <li>
              <a class="hover-link" href="https://upskill.com.bd/privacy"
                >Privacy Statement</a
              >
            </li>
            <li>
              <a class="hover-link" href="https://upskill.com.bd/about"
                >About Upskill</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-2">
          <h3 class="text-lg font-bold uppercase mb-4 font-heading">যোগাযোগ</h3>
          <div class="text-sm">
            <p>
              আপস্কিল, লেভেল ৩, হাউজ ৯,<br />
              লেইন ১৭/ই, ঢাকা ১২১৩
            </p>
            <p class="footer-phone">
              <!-- <i class="fa fa-phone"></i> -->
              ০১৭১৪০৫৩২১৩
            </p>
            <p class="footer-email">
              <!-- <i class="fa fa-envelope"></i> -->
              rafi.upskill@gmail.com
            </p>
            <!-- <h4 class="footer-widget-title">Follow Us</h4>
            <div id="social-follow"></div> -->
          </div>
        </div>
        <div class="col-md-4">
          <h3 class="text-lg font-bold uppercase mb-4 font-heading">
            আমাদের নিউজলেটারে সাবস্ক্রাইব করুন
          </h3>
          <div class="footer-newsletter">
            <div class="row">
              <div class="col-md-12">
                <input
                  type="text"
                  class="border-2 border-UGreen-light w-full bg-transparent p-2"
                  placeholder="আপনার ইমেইল টাইপ করুন"
                  v-model="email"
                />
              </div>
              <div class="col-md-12">
                <btn
                  class="
                    bg-transparent
                    border-2 border-UGreen-light
                    text-UGreen-light
                    font-bold
                    mt-2
                    w-1/2
                  "
                >
                  জমা দিন
                </btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import btn from "@/components/BaseButton.vue";
export default {
  components: {
    btn,
  },
  data() {
    return {
      email: "",
    };
  },
  mounted() {
    let linkedin = document.createElement("script");
    linkedin.setAttribute("type", "IN/FollowCompany");
    linkedin.setAttribute("data-id", "14598438");
    linkedin.setAttribute("data-counter", "bottom");
    // document.getElementById("social-follow").appendChild(linkedin);
  },
  methods: {
    subscribe() {
      this.$upskill
        .subscribe({ email: this.email })
        .then(() => {
          this.SuccessAlert("Successfully subscribed to the newsletter!");
        })
        .catch((err) => {
          this.ErrorAlert(err.data.message);
        });
    },
  },
};
</script>
<style scoped>
.mt-1 {
  width: 85px;
  height: auto;
}
</style>
