import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixins from "./util/global_mixins";
import "./index.css";
import upskill from "./upskill";
Vue.prototype.$upskill = upskill;
Vue.config.productionTip = false;

Vue.mixin(mixins);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
