<template>
  <div class="w-full">
    <div class="bg-UCPurple p-4 rounded">
      <div class="text-white font-bold text-lg">
        <i class="fas fa-medal"></i>
        Score
      </div>
      <div
        v-if="userScore > 0"
        class="text-UCYellow-light font-bold text-6xl font-heading"
      >
        {{ percentage }}%
      </div>
      <div v-if="userScore > 2">
        <div class="font-bold text-white text-lg text-4xl">Congratulations</div>
      </div>
      <div class="text-lg text-white">
        You got {{ userScore }} out of {{ totalScore }}!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["totalScore", "userScore", "percentage"]
}
</script>

<style></style>
