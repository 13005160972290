<template>
  <div class="w-full sm:w-3/4 2xl:w-full">
    <div v-if="(showScore || timeOver) && !assignmentType" class="mt-4">
      <Results />
    </div>
    <div v-else>
      <div>
        <div class="mb-2 w-full">
          <div v-show="!bangla" class="text-left font-bold">
            <span class="text-UCYellow-dark">Question {{ current + 1 }}</span
            >/ {{ questions.length }}
          </div>
          <div v-show="bangla" class="text-left font-bold">
            <span class="text-UCYellow-dark">প্রশ্ন {{ current + 1 }}</span
            >/ {{ questions.length }}
          </div>
          <h2 class="mt-4 text-left font-extrabold text-fontmd mb-6 format">
            <div v-if="questions[current].Other">
              <div v-if="questions[current].Other.question">
                <img :src="`https://upskill-files.s3.ap-southeast-1.amazonaws.com/${questions[current].Other.question}`" @click="openImg(`https://upskill-files.s3.ap-southeast-1.amazonaws.com/${questions[current].Other.question}`)" class="cursor-pointer"/>
              </div>
            </div>
            {{ questions[current].question_description }}
          </h2>
          <AssignmentOptions v-if="assignmentType" />
          <AnswerOptions v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Results from "@/components/Results.vue";
import AnswerOptions from "@/components/ContestAnswerOptions.vue";
import AssignmentOptions from "@/components/AssignmentOptions.vue";
export default {
  components: {
    Results,
    AnswerOptions,
    AssignmentOptions,
  },
  data() {
    return {};
  },
  computed: {
    current() {
      return this.$store.state.currentQuestion;
    },
    score() {
      return this.$store.state.userTotalScore;
    },
    totalScore() {
      return this.$store.state.totalScore;
    },
    questions() {
      return this.$store.state.questions;
    },
    showScore() {
      return this.$store.state.showScore;
    },
    showReview( ) {
      return this.$store.state.showReview;
    },
    assignmentType() {
      return this.$store.state.assignmentType;
    },
    timeOver() {
      return this.$store.state.quizEnd;
    },
    bangla() {
      return this.$store.state.bn;
    },
  },
  methods: {
    openImg(l) {
      window.open(l, "_blank")
    }
    // startQuizFunc() {
    //   this.startQuiz = true;
    //   //this.countDownTimer();
    // },
    // countDownTimer() {
    //   if (this.countDown > 0) {
    //     this.timer = setTimeout(() => {
    //       this.countDown -= 1;
    //       this.countDownTimer();
    //     }, 1000);
    //   } else {
    //     this.handleAnswerClick(false);
    //   }
    // },
  },
};
</script>

<style scoped>
.format{
  word-wrap: break-word;
  white-space: pre-wrap;
}

</style>
