<template>
  <div>
    <div v-if="(showScore || timeOver) && !assignmentType" class="mt-4">
      <Results />
    </div>
    <div v-else>
      <div class="text-center pt-4 pb-8">
        <h2 class="text-center font-extrabold lg:text-5xl text-gray-600">
          {{ info.quiz_title }}
        </h2>
        <div class="flex justify-center pt-4">
          <div><i class="fas fa-clock fa-2x mr-2 text-blueDs"></i></div>
          <div v-if="this.$store.state.quizTime != null">
            <AssignmentTimer v-if="!quizEnded" />
            <h2 v-else-if="quizEnded" class="font-bold text-lg">
              Assessment has ended !
            </h2>
          </div>
        </div>
        <div class="font-bold pb-6">
          <span class="text-grayDs text-2xl">Question {{ current + 1 }}</span
          >/ {{ questions.length }}
        </div>
      </div>

      <div class="container mx-auto px-4 pb-10 sm:pb-0">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:px-16">
          <div class="">
            <h3
              class="
                text-gray-800
                font-semibold
                text-3xl text-left
                md:max-w-md
                pb-2
              "
            >
              {{ questions[current].question_description }}
            </h3>
            <!-- <img src="https://picsum.photos/500/700" alt="" /> -->
            <div v-if="questions[current].Other">
              <div v-if="questions[current].Other.question">
                <img
                  :src="`https://upskill-files.s3.ap-southeast-1.amazonaws.com/${questions[current].Other.question}`"
                  @click="
                    openImg(
                      `https://upskill-files.s3.ap-southeast-1.amazonaws.com/${questions[current].Other.question}`
                    )
                  "
                  class="cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div class="">
            <AssignmentOptions v-if="assignmentType" />
            <AnswerOptions v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignmentTimer from '@/components/AssignmentTimer.vue'
import Results from '@/components/Results.vue'
import AnswerOptions from '@/components/AnswerOptionsA.vue'
import AssignmentOptions from '@/components/AssignmentOptions.vue'
export default {
  components: {
    AssignmentTimer,
    Results,
    AnswerOptions,
    AssignmentOptions,
  },
  data() {
    return {}
  },
  computed: {
    info() {
      return this.$store.state.info
    },
    current() {
      return this.$store.state.currentQuestion
    },
    score() {
      return this.$store.state.userTotalScore
    },
    totalScore() {
      return this.$store.state.totalScore
    },
    questions() {
      return this.$store.state.questions
    },
    showScore() {
      return this.$store.state.showScore
    },
    showReview() {
      return this.$store.state.showReview
    },
    assignmentType() {
      return this.$store.state.assignmentType
    },
    timeOver() {
      return this.$store.state.quizEnd
    },
  },
  methods: {
    openImg(l) {
      window.open(l, '_blank')
    },
    // startQuizFunc() {
    //   this.startQuiz = true;
    //   //this.countDownTimer();
    // },
    // countDownTimer() {
    //   if (this.countDown > 0) {
    //     this.timer = setTimeout(() => {
    //       this.countDown -= 1;
    //       this.countDownTimer();
    //     }, 1000);
    //   } else {
    //     this.handleAnswerClick(false);
    //   }
    // },
  },
}
</script>

<style scoped>
.format {
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
