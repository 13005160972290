<template>
  <div>
    <VueCountdown
      :time="deadline"
      :interval="100"
      v-slot="{ minutes, seconds }"
      class="text-white"
      @end="onCountdownEnd"
    >
      <span v-show="!assignmentType" class="text-blueDs text-2xl font-bold">
        {{ minutes }} min {{ seconds }} sec</span
      >

      <span v-show="assignmentType" class="text-blueDs text-2xl font-bold"
        >{{ minutes }} min {{ seconds }} sec</span
      >
    </VueCountdown>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'
import Swal from 'sweetalert2'
export default {
  components: {
    VueCountdown,
  },
  data() {
    return {
      countDown: '',
      timer: null,
      timeFormat: '',
      tick: '',
      type: '',
    }
  },
  computed: {
    format() {
      return this.timeFormat
    },
    deadline() {
      var date1 = new Date()
      var date = new Date(
        this.$store.state.session.end_time
          ? this.$store.state.session.end_time
          : this.countDown
      )
      var milliseconds = date.getTime() - date1.getTime()
      return milliseconds
    },

    assignmentType() {
      return this.$store.state.assignmentType
    },
    publicForm() {
      return this.$store.state.publicForm
    },
  },
  methods: {
    countDownTimer() {
      if (
        this.countDown > 0 &&
        this.type == 'h' &&
        !this.$store.state.quizEnd
      ) {
        this.timer = setTimeout(() => {
          this.countDown = this.countDown * 60
          this.countDown -= 1
          this.countDownTimer()
        }, 60000)
      } else if (
        this.countDown > 0 &&
        this.type == 'm' &&
        !this.$store.state.quizEnd
      ) {
        this.timer = setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 60000)
      } else if (
        this.countDown > 0 &&
        this.type == 's' &&
        !this.$store.state.quizEnd
      ) {
        this.timer = setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else if (this.countDown == 0 && !this.$store.state.quizEnd) {
        let args1 = {
          token: localStorage.getItem('session'),
        }
        this.$upskill.usrSubmitQuiz(args1).then((res) => {
          //this.submitInfo = res.data;
          this.$store.commit('submitQuiz', res.data)
          for (var i = 0; i < res.data.record_data.length; i++) {
            this.$store.state.actualResponse.push(
              res.data.record_data[i].actual_answer
            )
            this.$store.state.userResponse.push(
              res.data.record_data[i].user_answers
            )
          }
        })
        this.$store.state.quizEnd = true
      }
    },
    onCountdownEnd() {
      let args1 = {
        token: localStorage.getItem('session'),
      }
      if (this.$store.state.isPublic) {
        this.$upskill.pubSubmitQuiz(args1).then((res) => {
          //this.submitInfo = res.data;
          this.$store.commit('submitQuiz', res.data)
          for (var i = 0; i < res.data.record_data.length; i++) {
            this.$store.state.actualResponse.push(
              res.data.record_data[i].actual_answer
            )
            this.$store.state.userResponse.push(
              res.data.record_data[i].user_answers
            )
          }
        })
        //this.popup();
        this.Goto({ name: 'PublicResults' })
      } else if (this.$store.state.isGuest) {
        this.$upskill.guestSubmitQuiz(args1).then((res) => {
          //this.submitInfo = res.data;
          this.$store.commit('submitQuiz', res.data)
          for (var i = 0; i < res.data.record_data.length; i++) {
            this.$store.state.actualResponse.push(
              res.data.record_data[i].actual_answer
            )
            this.$store.state.userResponse.push(
              res.data.record_data[i].user_answers
            )
          }
        })
      } else {
        this.$upskill.usrSubmitQuiz(args1).then((res) => {
          //this.submitInfo = res.data;
          this.$store.commit('submitQuiz', res.data)
          for (var i = 0; i < res.data.record_data.length; i++) {
            this.$store.state.actualResponse.push(
              res.data.record_data[i].actual_answer
            )
            this.$store.state.userResponse.push(
              res.data.record_data[i].user_answers
            )
          }
        })
      }
      this.$store.state.quizEnd = true
    },
    popup() {
      Swal.fire({
        title: 'Put your username to see where you stand in the leaderboard',
        html: `<input type="text" id="name" class="swal2-input" placeholder="Username">`,
        confirmButtonText: 'Submit',
        focusConfirm: false,
        preConfirm: () => {
          const name = Swal.getPopup().querySelector('#name').value
          if (!name) {
            Swal.showValidationMessage(`Please enter your username`)
          }
          return { name: name }
        },
      }).then((result) => {
        //console.log(result.value.login);
        this.publicForm.username = result.value.name
        this.$upskill.pubSubmitDetails(this.publicForm).then(() => {
          this.SuccessAlert('submitted!')
        })
      })
    },
  },
  created() {
    this.countDown = this.$store.state.quizTime
    //this.countDownTimer();
  },
}
</script>

<style scoped></style>
