<template>
  <div>
    <Header></Header>
    <div class="flex flex-col-reverse sm:grid grid-cols-2 p-1 gap-4 mb-10">
      <div>
        <div class="mt-4 sm:mt-0 w-full place-self-start">
          <div
              class="
        bg-white
        my-12
        pb-6
        w-full
        justify-center
        items-center
        overflow-hidden
        md:max-w-lg
        rounded-lg
        shadow-sm
        mx-auto
      "
          >
            <div>
              <h1 class="text-lg text-center font-semibold">
                Quiz report of
              </h1>
              <h1 class="text-3xl text-center font-semibold">
                {{ info.username }}
              </h1>
              <p class="text-gray-600 text-center mt-3">
                Attended on
                <strong>{{ parseTime(info.created_at) }}</strong>
              </p>
            </div>
            <div class="mt-6 pt-3 mx-6 border-t">
              <div
                  class="
            text-sm
            mr-2
            my-1
            tracking-wider
            border
            px-2
            bg-UCPurple
            text-white
            rounded-md
            py-1
          "
              >
                Quiz Title: {{ info.QuizTitle }}
              </div>
              <div
                  class="
            text-sm
            mr-2
            my-1
            rounded-md
            tracking-wider
            border
            px-2
            border-indigo-600
            bg-UCPurple
            text-white
            py-1
          "
              >
                Time allocated: {{ info.time_required }}
              </div>
              <div
                  class="
            text-sm
            mr-2
            my-1
            rounded-md
            tracking-wider
            border
            px-2
            border-indigo-600
            bg-UCPurple
            text-white
            py-1
          "
              >
                Domain: {{ info.domain }}
              </div>
            </div>
          </div>
          <LeaderBoard
            :leaderboardData="leaderboardData"
            :total="stat.question_score"
          />
        </div>
      </div>
      <div
        class="grid grid-cols-1 gap-2 place-items-start mt-8 lg:w-3/4 2xl:w-3/5"
      >
        <Result
          :totalScore="stat.question_score"
          :userScore="stat.user_score"
          :percentage="stat.percentage"
        />
        <Review :data="quizResult"></Review>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// import leftCover from "@/components/LeftSection.vue";
import Header from "../components/CHeader";
import Footer from "../components/Footer";
import Review from "@/components/Review2.vue";
import Result from "@/components/Results2.vue";
import LeaderBoard from "@/components/LeaderBoard2.vue";
import moment from "moment";
import axios from "axios";
export default {
  props: ["items", "time"],
  components: {
    Header,
    Footer,
    Review,
    Result,
    LeaderBoard,
  },
  data() {
    return {
      leaderboardData: [],
      quizResult: [],
      stat: { question_score: 0, user_score: 0, percentage: 0 },
    };
  },
  computed: {
    info() {
      return this.quizResult[0];
    },
  },
  created() {
    this.getLeaderboard();
    this.getQuizResult();
  },
  methods: {
    parseTime(t) {
      return moment(t).format("lll");
    },
    getLeaderboard(id) {
      this.$upskill
        .getLeaderBoard(id)
        .then((res) => {
          this.leaderboardData = res.data.data;
        });
    },
    getQuizResult() {
      let quizSession = this.$route.params.id
      axios
        .get(
          `https://quizmsvc.upskill.com.bd/public/quizresult/${quizSession}`
        )
        .then((res) => {
          this.quizResult = res.data.data;
          this.quizResult.forEach((o) => {
            this.stat.question_score += o.question_score;
            this.stat.user_score += o.user_score;
          });
          this.stat.percentage = parseInt(
            (this.stat.user_score / this.stat.question_score) * 100
          );
          this.getLeaderboard(this.quizResult[0].quiz_id)
        }).catch(() => {
          this.$router.go(-1)
      });
    },
  },
};
</script>
