<template>
  <div class="container mx-auto p-2" id="wrapper">
    <nav class="nav p-2 sm:p-4">
      <img
        src="../assets/UC-logo.png"
        alt="Upskill classroom logo"
        width="160px"
        height="auto"
      />
      <div
        class="
          sm:flex
          sm:space-x-4
          sm:items-center
          uppercase
          font-heading
          text-sm
        "
        id="menu"
      >
        <a class="font-extrabold" href="https://upskillclassroom.com">হোম</a>
        <a
          class="font-extrabold"
          href="https://www.upskillclassroom.com/teacher"
          >শিক্ষকগণ</a
        >
        <a
          class="font-extrabold"
          href="https://www.upskillclassroom.com/blogs/blog"
          >ব্লগ</a
        >
        <a class="font-extrabold" href="https://www.upskillclassroom.com/faq"
          >জিজ্ঞাসা</a
        >
        <a class="font-extrabold" href="https://www.upskillclassroom.com/quiz"
          >কুইজ</a
        >
      </div>
      <div class="flex sm:items-center lg:-space-x-16" id="signup">
        <btn
          v-if="loggedIn"
          class="
            hidden
            sm:block
            bg-UCPink
            text-white text-sm
            shadow-UCPink
            w-1/2
          "
          @click="logout()"
          >লগ আউট</btn
        >

        <btn
          v-else-if="!loggedIn && !isPublic"
          class="
            hidden
            sm:block
            bg-UCPink
            text-white text-sm
            shadow-UCPink
            w-1/2
          "
          >প্রবেশ করুন</btn
        >
      </div>
    </nav>
    <div class="custom-mobile-menu">
      <i
        @click="showMobile()"
        class="sm:hidden fas fa-bars fa-2x"
        :class="{
          'mobile-menu': open,
          animated: open,
        }"
      ></i>
      <div
        class="hidden uppercase font-heading text-sm text-left"
        id="mobilemenu"
        :class="{
          'mobile-menu': open,
          animated: open,
        }"
      >
        <a class="font-extrabold" href="https://upskillclassroom.com">হোম</a>
        <a
          class="font-extrabold"
          href="https://www.upskillclassroom.com/teacher"
          >শিক্ষকগণ</a
        >
        <a
          class="font-extrabold"
          href="https://www.upskillclassroom.com/blogs/blog"
          >ব্লগ</a
        >
        <a class="font-extrabold" href="https://www.upskillclassroom.com/faq"
          >জিজ্ঞাসা</a
        >
        <a class="font-extrabold" href="https://www.upskillclassroom.com/quiz"
          >কুইজ</a
        >
        <btn
          v-if="!loggedIn && !isPublic"
          class="
            hidden
            sm:block
            bg-UCPink
            text-white text-sm
            shadow-UCPink
            w-1/2
          "
          >প্রবেশ করুন</btn
        >
        <btn
          v-if="loggedIn"
          class="
            hidden
            sm:block
            bg-UCPink
            text-white text-sm
            shadow-UCPink
            w-1/2
          "
          >প্রবেশ করুন</btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import btn from "@/components/BaseButton.vue";
export default {
  components: {
    btn,
  },
  data: () => {
    return {
      open: false,
      userLoggedIn: false,
    };
  },
  computed: {
    loggedIn() {
      return this.SignedIn();
    },
    isPublic() {
      return this.$store.state.isPublic;
    },
  },
  methods: {
    clickMobileMenu() {
      this.opened = !this.opened;
      this.show = !this.show;
    },
    logout() {
      this.Logout();
      this.userLoggedIn = false;
    },
    showMobile() {
      this.open ? (this.open = false) : (this.open = true);
    },
  },
};
</script>
<style scoped>
.nav {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
}
#nav-item {
  font-weight: bold;
}
#menu .router-link-exact-active {
  color: #0062ff;
  cursor: pointer;
}
.icon {
  margin: 0;
  padding: 0;
}
.mobile-menu {
  opacity: 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: end;
}
.animated {
  animation: appear 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 767.98px) {
  .nav {
    grid-template-columns: 1fr;
    margin-right: 20px;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    justify-items: end;
    align-items: center;
  }
  #menu {
    display: none;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }
  #signup {
    display: none;
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center; */
  }
  #mobilemenu {
    padding-top: 10px;
    margin-right: 10px;
  }
  .custom-mobile-menu i {
    margin-right: 10px;
  }
}
</style>
