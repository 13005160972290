<template>
  <div>
    <CHeader v-show="!bangla" />
    <CHeaderBn v-show="bangla" />
    <Quiz :items="questions"></Quiz>
    <Footer v-show="!bangla" class="mt-2" />
    <FooterBn v-show="bangla" class="mt-2" />
  </div>
</template>

<script>
// @ is an alias to /src
import Quiz from "@/components/QuizLayout.vue";
import Footer from "@/components/Footer.vue";
import FooterBn from "@/components/FooterBn.vue";
import CHeader from "@/components/CHeader.vue";
import CHeaderBn from "@/components/CHeaderBn.vue";

export default {
  name: "Assignment",
  components: {
    Quiz,
    Footer,
    CHeader,
    FooterBn,
    CHeaderBn,
  },
  data() {
    return {
      quizID: "",
      isStarted: false,
      questions: [],
      info: {},
      form: "",
      hasQuizStarted: false,
    };
  },
  computed: {
    showScore() {
      return this.$store.state.showScore;
    },
    getForm() {
      return this.$store.state.form;
    },
    bangla() {
      return this.$store.state.bn;
    },
  },
  methods: {
    getQuizzes() {
      this.$upskill.usrGetQuizzesByOrgID("upskill").then((res) => {
        this.info = res.data.data.find((o) => o.quiz_id == this.quizID);
        this.startQuizSession();
      });
    },
    startQuizSession() {
      //this.getForm.quiz_id = this.quizID;
      this.$upskill.usrgetAssignmentByID(this.quizID).then((res) => {
        this.$store.state.assignmentType = true;
        delete res.data.message;
        res.data.answers = "";
        this.$store.state.info = res.data.quiz;
        this.$store.state.assignmentRes = res;
        this.questions = res.data.questions;
        this.$store.state.quizTime = res.data.quiz.Other.ends_at;
        // if (res.data.records != null) {
        //   this.$store.state.currentQuestion = res.data.records.length;
        // }
        // if (this.showScore) {
        //   this.$store.dispatch("resetThings");
        // }
        this.$store.state.questions = this.questions;
        //console.log(res.data.data.session_token);
        //console.log(res.data);
      });
    },
  },
  created() {
    this.quizID = this.$route.params.id;
    const bn = this.$route.query.lang;
    let queryToken = this.$route.query.token;
    let token = "";
    if (bn) {
      this.$store.state.bn = true;
    }
    this.$upskill.decodeToken(queryToken).then((res) => {
      token = res.data.data;
      localStorage.setItem("session", token);
      //this.getQuizzes();
      this.startQuizSession();
    });
  },
};
</script>
