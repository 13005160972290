var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.questions[_vm.current].question_type == 'MCQ')?_c('div',{staticClass:"flex flex-col justify-items-start"},_vm._l((_vm.questions[_vm.current].options),function(option,index){return _c('div',{key:index,staticClass:"\n        md:max-w-screen-md\n        flex\n        justify-between\n        items-center\n        options\n        cursor-pointer\n        border border-gray-100\n        py-3\n        my-2\n        text-black\n        font-semibold\n        text-xl\n        bg-gray-200\n        rounded-lg\n      ",class:{
        'bg-UCYellow-dark': _vm.clickedIndex == index,
        'text-white': _vm.clickedIndex == index,
      },on:{"click":function($event){return _vm.handleAnswerClick(index, option)}}},[_vm._v(" "+_vm._s(option)+" ")])}),0):(_vm.questions[_vm.current].question_type == 'image')?_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-3 gap-2"},_vm._l((_vm.questions[_vm.current].Other.images),function(option,index){return _c('div',{key:index,staticClass:"cursor-pointer",on:{"click":function($event){_vm.handleAnswerClick(
          index,
          _vm.getKeyByValue(_vm.questions[_vm.current].Other.images, option)
        )}}},[_c('img',{staticClass:"rounded-lg img-size",class:{
          'active-img': _vm.clickedIndex == index,
        },attrs:{"src":option.imgURL,"alt":option.label}})])}),0):_vm._e(),_c('div',{staticClass:"flex flex-col justify-items-start w-full mt-4"},[(!_vm.showSubmit)?_c('btn',{staticClass:"\n        bg-blueDs\n        disabled:opacity-50\n        text-white\n        mt-4\n        w-full\n        h-12\n        transition\n        duration-500\n        ease-in-out\n        hover:bg-blueDs\n        2xl:h-16\n      ",attrs:{"disabled":!_vm.selectedAns},on:{"click":function($event){return _vm.goNext()}}},[_vm._v(" next "),_c('i',{staticClass:"fas fa-arrow-right"})]):_vm._e(),_c('btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSubmit),expression:"showSubmit"}],staticClass:"\n        disabled:opacity-50\n        bg-blueDs\n        text-white\n        mt-4\n        w-full\n        h-12\n        transition\n        duration-500\n        ease-in-out\n        hover:bg-UCDarkPink\n        2xl:h-16\n      ",attrs:{"disabled":!_vm.showSubmit},on:{"click":function($event){return _vm.goNext()}}},[_vm._v("Submit")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }